import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import FindPair from "./FindPair";
import Connection from "./Connection";
import GameQuiz from "./Quiz";
import FindPath from "./FindPath";
import FindWords from "./FindWords";
import RearrangeProducts from "./RearrangeProducts";
import ImageDragAndDrop from "./ImageDragAndDrop";
import CharDragAndDrop from "./CharDragAndDrop";
import ImageSwap from "./ImageSwap"
import FindOddOne from "./FindOddOne"
import FindFlow from "./FindFlow"
import SpinWheel from "./SpinWheel"
import Matching from "./Matching"
import HiddenObject from "./HiddenObject";
import MemoryObject from "./MemoryObject";
import { WelComePage } from "../Common/WelcomePage";
import { ThankYouPage } from "../Common/ThankYouPage";
import { LeadFormBlock } from "../Common/Leadform";
import { getDivId, isNotEmpty, isNotEmptyJson } from "../../Utils/helper";
import WordConnect from "./WordConnect";
import { GetDEviceInfo } from "../../../backend/GetDeviceInfo";
import { CampaignEvents } from "../../../backend/campaignEvents";
import SpinWheelSideTab from "./spinWheelSideTab";
import { EngageLoading } from "../../Common/EngageLoading";
import { CountDownTimer } from "../Common/CountDownTimer";
import { CountUpTimer } from "../Common/CountUpTimer";
import "../../Common/errBoardStyle.css";
import SpinWheelThankyou from "./SpinWheel/SpinWheelThankyou";
import { GameMenuBar } from "../Common/gameMenu";
import { LetsPlayBanner } from "../Common/LetPlaybanner";
import { ReadyBannerImg } from "../Common/ReadyImgbanner";
import { PlayBannerImg } from "../Common/Playbanner";
import { TimesUpBanner } from "../Common/timesupBanner";
import { Personalization } from "./Personalization";



export const 
Games = ({ fieldID, filedsObj }) => {

  const { campaignID, siteId, renderType, primary_Site_id, campaignSettings } = useSelector((state) => ({ campaignID: state.campaign_id, siteId: state.site_id, renderType: state.renderType, primary_Site_id: state.primary_Site_id, campaignSettings: state.campaignSettings }))

  // Base state
  const childStateRef = useRef();
  const [templateType, settemplateType] = useState(filedsObj?.type)
  const [Settings, setSettings] = useState(filedsObj?.filedContent)
  const [timerstatus, settimerstatus] = useState(filedsObj?.filedContent?.timerstatus)
  const [DIVID, setDivId] = useState(getDivId())
  const [LoadContent, setLoadContent] = useState(true);
  const [gamePlayTime, setGamePlayTime] = useState([0, 0]);
  const [gamePlaySeconds, setGamePlaySeconds] = useState(0)
  const [ActionCount, setActionCount] = useState(0);
  const [formWheelObj, setformWheel] = useState({})
  const [wheelFormEvent, setWheelFormEvent] = useState(null)
  const BrandLabel = Settings?.brandLabel ?? true




  // Screens To show
  const [startSce, setstartSce] = useState(true);
  const [resultSce, setresultSce] = useState(false);
  const [LeadSce, setLeadSce] = useState(false);

  const [startSceBanner, setstartSceBaneer] = useState(false);
  const [TimesUpBannerSec, setTimesUpBaneer] = useState(false);

  // Timer and starting and finishing the games
  const [second, setSecond] = useState(0);
  const [min, setMin] = useState(0);
  const [preTimer, setPreTimer] = useState(false);

  const [StartTime, setStartTime] = useState(false);
  const [PreStartTime, setPreStartTime] = useState(false);
  const [gameInit, setGameInit] = useState(false);
  const [GameFinsh, setGameFinsh] = useState(true);
  const [connectionStartTime, setConnntionStartTime] = useState(false);
  const [GameShffleImg, setGameShffle] = useState(false);
  const [connectionTimeOut, setConnectionTimeOut] = useState(false);
  const [ userAns, SetUserAns ] = useState([]);

  // Hide Screen based on current screen
  useEffect(() => {
    if (startSce) { setresultSce(false); setLeadSce(false) }
    if (resultSce) { setstartSce(false); setLeadSce(false) }
    if (LeadSce) { setresultSce(false); setstartSce(false) }
    if (gameInit) { setresultSce(false); setstartSce(false); setLeadSce(false) }
  }, [startSce, resultSce, LeadSce, gameInit])

  // Calculating Total playing seconds while Game finish
  useEffect(() => {
    if (gamePlayTime?.length > 0 && isNotEmpty(gamePlayTime[0]) && isNotEmpty(gamePlayTime[1]) && gamePlayTime[0] !== 0 && gamePlayTime[1] !== 0) {
      let timeDiff = Math.abs(gamePlayTime[0] - gamePlayTime[1]);
      timeDiff = parseFloat((timeDiff / 1000).toFixed(2))
      timeDiff = Math.round(timeDiff)
      setGamePlaySeconds(timeDiff)
    }
  }, [gamePlayTime])


  const GameStart = async () => {

    if (!LoadContent) {

      setGameFinsh(false)

      setSecond(0); setMin(0); setActionCount(0);

      setGameInit(true);

      setConnntionStartTime(true);

      childStateRef?.current?.GameStart()

      if (renderType === "liveRender") {

        const DEviceData = await GetDEviceInfo()

        const CampEvent = new CampaignEvents({ campaignId: campaignID, site_id: siteId, primary_Site_id: primary_Site_id })

        CampEvent.clicked(DEviceData)

      }

    }

  }



  useEffect(() => {

    if (gameInit && GameFinsh) {

      setConnectionTimeOut(false)
      setConnntionStartTime(false)

      setGameInit(false);
      setStartTime(false);

      setGamePlayTime(obj => [obj[0], Date.now()]);

      if (templateType === "spinWheel") setresultSce(true); else
        if (Settings?.leadfrom && isNotEmptyJson(Settings?.game_leadForm_json)) setLeadSce(true); else setresultSce(true);
    }
  }, [GameFinsh])


  // Timer Trigger while game start
  useEffect(() => {

    if (gameInit === true) {

      if ((templateType === "puzzle-shuffleimage" && Settings?.game_mainpage_json?.screenMode === 'single') || templateType === "rearrange") {

        setPreTimer(true);

      } else {

        setPreTimer(false)
        setStartTime(true);
        setGamePlayTime([Date.now(), 0])

      }

    }

    else { setPreTimer(false) }

  }, [gameInit])


  const submitLeadForm = () => { if (templateType === "spinWheel") GameStart(); else { setLeadSce(false); setresultSce(true); } }

  const PreTimeFinish = () => {

    setSecond(0); setMin(0); setActionCount(0);
    setPreStartTime(false)

    if ((templateType === 'puzzle-shuffleimage' && Settings?.game_mainpage_json?.screenMode === 'single')||(templateType === 'rearrange')) {

      setGameShffle(true);

      setTimeout(() => {

        setStartTime(true);
        setPreTimer(false)

      }, 2000)

    }

    else {

      setStartTime(true);
      setPreTimer(false)

    }

    setGamePlayTime([Date.now(), 0])
  }


  // Styles based on games
  let heightType = "h-full";
  let notScreenfitGames = ["puzzle-shuffleimage", "puzzle-word", "connection", "memoryObject", "findOddOne", "spinWheel"];
  if (notScreenfitGames.includes(templateType)) heightType = ""

  if (templateType === "spinWheel" && Settings?.game_mainpage_json?.spinWheelType === 'default') heightType = "h-full"
  if (templateType === 'puzzle-shuffleimage' && Settings?.game_mainpage_json?.screenMode === 'single') heightType = "h-full"

  let paddingType = (templateType === "spinWheel" ? "unset" : "");
  let marginType = (templateType === "spinWheel" ? "unset" : "");

  if (templateType === "rearrange") heightType += " overflow-auto divScroll"

  // Component data passing
  var data = { setPreStartTime, DIVID, setActionCount: setActionCount, GameFinsh: setGameFinsh, isGameFinsh: GameFinsh, Settings: Settings?.game_mainpage_json, leadSettings: Settings?.game_leadForm_json, setLoadContent, showcardtime: Settings?.showcardtime, ActionCount, setStartTime, gameInit, LoadContent, SetUserAns }
  var leadData = { submit: submitLeadForm, setLoadContent, DIVID, Settings: Settings?.game_leadForm_json, gamePlaySeconds: gamePlaySeconds, isDefaultSpinWheel: (templateType === "spinWheel" && Settings?.game_mainpage_json?.spinWheelType === 'default') }
  var timerData = { actionCount: ActionCount, timeCount: (Settings?.timeLimet), Callback: () => { Settings?.timerType === "CountDown" ? setTimesUpBaneer(true) : setGameFinsh(true) }, StartTime: StartTime, setMin: setMin, setSecond: setSecond }
  var connectiontimerData = { actionCount: ActionCount, timeCount: (Settings?.timeLimet), Callback: () => { Settings?.timerType === "CountDown" ? setTimesUpBaneer(true) : setTimesUpBaneer(true) }, StartTime: connectionStartTime, setMin: setMin, setSecond: setSecond }
  var preTimerData = { timeCount: (Settings?.showcardtime || 3), Callback: PreTimeFinish, StartTime: ((PreStartTime === true && preTimer === true && LoadContent === false)), setMin: setMin, setSecond: setSecond }

  const TimerNextAction = () => {
   
    if( templateType !== 'connection' ) setGameFinsh(true);

    else {

      setConnntionStartTime(false);
      setConnectionTimeOut(true);
    }
    
  }

  data =  {
    ...data,
    GameShffleImg,
    StartTime,
    "TimeOut":connectionTimeOut,
    ConnntionStartTimeFun: setConnntionStartTime ,
    ConnectionTimeOutFun:setConnectionTimeOut,
    pauseTimer :  () => true ,
    startTimeFun: () => { setConnectionTimeOut(false) ; setConnntionStartTime(true)  },
  }

  return (
    <>
      {LoadContent && <EngageLoading></EngageLoading>}

      <div id={"gameFrameBorder" + DIVID} className={`gamecontainer overflow-hidden ${heightType}  ${(LoadContent ? "opacity-0" : "opacity-100")}`} style={{ padding: paddingType, margin: marginType, background: Settings?.background?.type === "image" ? `url(${Settings?.background?.src ?? "/asset/images/quizzes/background.png"}) 0% 0% / 100% 100% no-repeat` : Settings?.background?.color ?? "#FFFFFF" }}>

        { preTimer && <CountDownTimer {...preTimerData} preTimer={preTimer} />  }

        {timerstatus && (
        preTimer ? <></> : (
          Settings?.timerType === "CountDown" ?
            <CountDownTimer key={StartTime} {...(templateType !== 'connection') ? timerData: connectiontimerData }  /> :
            <CountUpTimer {...timerData} />
          ))}

      { ( Settings?.backgroundMusic ?? true ) && <GameMenuBar isGameStart ={ startSce } Src={ Settings?.backgroundMusicSrc } />}


        {startSce && (
          templateType !== "spinWheel" && Settings?.game_startscreen_json?.displayView ? <WelComePage WelComePageObJ={Settings?.game_startscreen_json}  bannerAction={setstartSceBaneer} StartAction={() => { setstartSce(false); }} /> :
            templateType === "spinWheel" && Settings?.game_mainpage_json?.spinWheelType === 'default' && Settings?.leadfrom ? <LeadFormBlock  {...leadData} setWheelFormEvent={setWheelFormEvent} isFor={"spinWheel"} setformWheel={setformWheel} childStateRef={childStateRef} /> : null
        )}

        {

          (startSceBanner && (templateType !== 'puzzle-shuffleimage' && Settings?.game_mainpage_json?.screenMode !== 'single') && (templateType !== 'rearrange')) ?

            <LetsPlayBanner bannerAction={setstartSceBaneer} StartAction={() => { GameStart() }} />

            :

            startSceBanner && <ReadyBannerImg bannerAction={setstartSceBaneer} StartAction={() => { GameStart() }} />

        }

        { GameShffleImg && <PlayBannerImg bannerAction={setGameShffle} StartAction={() => true} /> }

        {
          templateType === "findpair" ? (
            Settings.game_mainpage_json.pairType === "match" ? <Matching ref={childStateRef} data={data} /> :
              <FindPair ref={childStateRef} data={data} />
          ) :
            templateType === 'puzzle-shuffleimage' ? (
              Settings?.game_mainpage_json?.screenMode === 'single' ? <ImageSwap ref={childStateRef} data={{...data,GameShffleImg}} /> :
                Settings?.game_mainpage_json?.screenMode === 'double' ? <ImageDragAndDrop ref={childStateRef} data={data} /> : null
            ) :
              templateType === 'puzzle-word' ? (
                // Settings?.game_mainpage_json?.screenMode === 'single' ? <RearrangeProducts  ref={childStateRef}     data={data}    />:
                Settings?.game_mainpage_json?.screenMode === 'double' ? <CharDragAndDrop ref={childStateRef} data={data} /> : null
              ) :

                templateType === "spinWheel" ? (
                  Settings?.game_mainpage_json?.spinWheelType === 'default' ? <SpinWheel ref={childStateRef} data={{ ...data, BrandLabel: (Settings?.brandLabel ?? true) }} /> :
                    Settings?.game_mainpage_json?.spinWheelType === 'sideTab' ? <SpinWheelSideTab ref={childStateRef} data={{ ...data, submit: submitLeadForm, BrandLabel: (Settings?.brandLabel ?? true) }} /> : null
                ) :

                      templateType === "rearrange" ? <RearrangeProducts ref={childStateRef} data={data} /> :
                      templateType === "findpath" ? <FindPath ref={childStateRef} data={data} /> :
                      templateType === "findOddOne" ? <FindOddOne ref={childStateRef} data={data} /> :
                      templateType === 'personalize-quiz' ? <Personalization ref={childStateRef} data={data} /> :
                      templateType === "connection" ? <Connection ref={childStateRef} data={data} /> :
                      templateType === "findFlow" ? <FindFlow ref={childStateRef} data={data} /> :
                      templateType === "Q&A" ? <GameQuiz ref={childStateRef} data={data} /> :
                      templateType === "findWord" ? <FindWords ref={childStateRef} data={data} /> :
                      templateType === "wordConnect" ? <WordConnect ref={childStateRef} data={data} /> :
                      templateType === "hiddenObject" ? <HiddenObject ref={childStateRef} data={data} /> :
                      templateType === "memoryObject" ? <MemoryObject ref={childStateRef} data={data} /> :
                      null
        }

        {templateType === "spinWheel" ?

          null :

          <>{

            BrandLabel && 
            // <div className="w-full  flex   mt-[10px]   right-[3px] "  >

            //   <div className="relative mx-auto w-[100%] px-1 sm:px-2 flex flex-wrap justify-end " >

            //     <div className="flex w-fit flex-col space-y-2  bg-white rounded-lg py-1 sm:py-2 h-fit px-2 sm:px-4 my-auto shadow-sm">

            //       <p className="flex flex-row space-x-1 sm:space-x-2 text-[12px] leading-[17px] sm:text-[14px] sm:leading-[20px] m-auto"><span className="my-auto">Powered By</span> <span className="font-bold my-auto text-base">GoZen</span></p>
            //     </div>
            //   </div>
            // </div>
                    <div className="absolute bottom-0 left-0 rounded-t-lg bg-white justify-center py-1 w-full border-1" >

                    <p className="text-sm text-center m-0 text-black font-semibold " >Powered by GoZen Engage</p>
          
                  </div>
          }</>
        }

        {(LeadSce && templateType !== "spinWheel") && (<LeadFormBlock  {...leadData} />)}

        { TimesUpBannerSec && <TimesUpBanner ResultSrc={ TimerNextAction } bannerAction={setTimesUpBaneer} />}

        {resultSce && <>
          {
            templateType === "spinWheel" && Settings?.game_mainpage_json?.spinWheelType === 'default' ?

              <SpinWheelThankyou wheelFormEvent={wheelFormEvent} formWheelObj={formWheelObj} setformWheel={setformWheel} DIVID={DIVID} Settings={Settings?.game_mainpage_json} result={childStateRef?.current?.getSpinResult()} /> :

              <ThankYouPage timerstatus={timerstatus} DIVID={DIVID} Settings={Settings} templateType={templateType} userAns={userAns} gamePlaySeconds={second + (min * 60)} score={childStateRef?.current?.getScore()} ActionCount={ActionCount} ThankYouPageObj={Settings?.game_resultcreen_json?.results} restartAction={() => {setresultSce(false);setstartSceBaneer(true)}} timing={`${min < 10 ? `0${min}` : second} : ${second < 10 ? `0${second}` : second}`} gamePlayTime={gamePlayTime} />

          }

        </>

        }
      </div>
    </>
  )
}